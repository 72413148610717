import { areaPerms, InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authorisationGuard } from '../../shared/guards/authorisation.guard';
import { Access } from '@shared/models/auth/Access';

export const clientsAreaRouteData: InlightsRoute[] = [
	{
		path: 'clients',
		title: 'Clients',
		icon: 'apartment',
		showInMenu: true,
		data: {
			allowAnonymousAccess: false,
			requiredClaims:[areaPerms(x => x.clients, [Access.Read, Access.Write])],
		},
		canActivate: [authorisationGuard],

		loadComponent: () => import('./pages/client-index/client-index.component').then((m) => m.ClientIndexComponent),
		children: [
			{
				title: 'Client Details',
				path: 'details/:id',
				loadComponent: () => import('./pages/client-details-page/client-details-page').then((m) => m.ClientDetailsPageComponent),
				canActivate: [authorisationGuard],

			},
		],
	},
];
